<template>
    <div class="payment-methods-wrapper d-flex flex-column">
        <Breadcrumbs ref="header" class="mx-1" :active="2"></Breadcrumbs>
        <!-- <a :href="dataDeepLink">lien ici</a> -->
        <v-radio-group class="mx-4 payment-methods-list" v-model="selectedPaymentMethod" :style="{'max-height': `calc(calc(var(--vh, 1vh) * 100) - ${headerHeight}px )`}" hide-details>
            <PaymentMethodListBloc v-for="(paymentMethod, key) in paymentMethodsToShow" :key="`payment-method-${key}`" :paymentMethod="paymentMethod" @paymentMethodChanged="selectedPaymentMethod = $event"></PaymentMethodListBloc>
        </v-radio-group>
        <!-- <v-checkbox class="custom-check-btn my-0 align-self-center" v-model="generalTermsAccepted" hide-details>
            <template v-slot:label>
                <span class="primary--text text-caption">{{$t('agreeTermsPaymentMethods')}}</span>
            </template>
        </v-checkbox> -->
        <v-spacer></v-spacer>
        <div ref="footer" class="mx-4 my-4 d-flex flex-column align-center justify-center payment-methods-footer">
            <div class="full-width d-flex flex-column align-center justify-center">
                <v-btn class="my-2" fab elevation="0" dark color="primary" width="48" height="48" @click="$router.push({ name: 'ScanProduct' })"> <v-img width="24" height="24" src="@/assets/img/icon-scan.png" contain></v-img> </v-btn>
            </div>
            <span class="my-1 text-center text-caption red--text text--darken-2" v-if="payBtnClicked && !generalTermsAccepted">{{$t("warningPaymentMethods")}}</span>
            <template v-if="generalTermsAccepted && selectedPaymentMethodObject && selectedPaymentMethodObject.componentName != null">
                <component :is="selectedPaymentMethodObject.componentName" :amount="basketTotalAmount" :paymentConfig="selectedPaymentMethodObject.config" :nextRoute="selectedPaymentMethodObject.nextRoute"></component>
            </template>              
            <v-btn v-else :class="{'py-5 payment-methods-footer-btn': true, 'disabled': !selectedPaymentMethodObject}" color="primary accent-1" @click="handleCheckoutClick(selectedPaymentMethodObject)" rounded> {{$t("payPaymentmethods", {amountToPay: formatPrice(basketTotalAmount)})}} </v-btn>
        </div>
        
    </div>
</template>

<script>
    import Breadcrumbs from "@/components/Breadcrumbs";
    import ApplePay from "@/components/payment-methods/ApplePay";
    import PaymentMethodListBloc from "@/components/PaymentMethodListBloc";
    import GooglePay from "@/components/payment-methods/GooglePay";
    import { mapState } from 'vuex'

    import { getPaymentMethodsByDevice } from "@/assets/js/PaymentMethods"
    import { getDeviceType } from "@/assets/js/Utils"
    import {pageInfoMixin} from "@/mixins/pageInfoMixin"

    export default {
        name: "PaymentMethods",
        components: {
            ApplePay,
            PaymentMethodListBloc,
            GooglePay,
            Breadcrumbs
        },
        mixins: [pageInfoMixin],
        data() {
            return {
                payBtnClicked: false,
                generalTermsAccepted: true,
                selectedPaymentMethod: -1,
                paymentMethods: getPaymentMethodsByDevice(getDeviceType()),
                comment: {},
                footerHeight: 0,
                componentHeaderHeight: 0,
                dataDeepLink: ""
            }
        },
        created(){
            let queryParams = this.getQueryParams()

            console.log("DLN query params", queryParams);
            if(queryParams.data ){
                let parsedQueryParams = JSON.parse(queryParams.data)
                if(parsedQueryParams.result == "WPI_RESULT_SUCCESS"){
                        this.comment.paymentReference = parsedQueryParams.paymentSolutionReference
                        this.updateComment({...this.basketComment, ...this.comment})
                        this.$router.push({name: "Ticket"})
                }else if (parsedQueryParams.result == "WPI_RESULT_FAILURE") {             
                    this.showSnackbar({
                        message: this.$t('paymentError'),
                        type: "error",
                        timeout: 10
                    })
                }
            }
        },
        mounted () {
            this.footerHeight = this.$refs.footer.offsetHeight
            this.componentHeaderHeight = this.$refs.header.offsetHeight
        },
        methods:{
            handleCheckoutClick(route){
                if(this.generalTermsAccepted && this.selectedPaymentMethodObject){
                    this.comment.paymentMethod = this.selectedPaymentMethodObject.name
                    this.updateComment({...this.basketComment, ...this.comment})
                    if(this.selectedPaymentMethodObject.deepLinkUrl){
                        console.log("DLN selectedPaymentMethodObject",this.selectedPaymentMethodObject);
                        this.showLoader({
                            message: this.$t("scanTerminalLoaderText"),
                            color: this.$vuetify.theme.themes.light.primary
                        })
                        window.location.replace(this.selectedPaymentMethodObject.deepLinkUrl(this.basketTotalAmount*100, this.selectedPaymentMethodObject.returnUrl))
                    }else{
                        this.$router.push(this.selectedPaymentMethodObject.nextRoute)
                    }
                    
                }else{
                    this.payBtnClicked = true
                }
            },
            getQueryParams(){
                const params = {};
                const queryString = window.location.search.substring(1);
                const pairs = queryString.split("&");
                for (const pair of pairs) {
                    const [key, value] = pair.split("=");
                    params[decodeURIComponent(key)] = decodeURIComponent(value);
                }
                return params;
            }
        },
        computed: {
            ...mapState(['standalone']),
            selectedPaymentMethodObject(){
                if(this.selectedPaymentMethod > -1){
                    return this.paymentMethods.find(paymentMethod => this.selectedPaymentMethod == paymentMethod.id )
                }
                return null
            },
            canPay(){
                return this.generalTermsAccepted
            },
            paymentMethodsToShow(){
                return this.paymentMethods.filter(method => method.name != "paymentMethodApplePay" && method.name != "paymentMethodGooglePay"  && method.name != "paymentMethodSips")
            }
        }
    }
</script>

<style scoped>
    .payment-methods-wrapper {
        height: 100%;
    }
    .payment-methods-footer-btn{
        width: 100%;
    }
    .payment-methods-footer-btn.disabled{
        opacity: 0.55;
    }
    .payment-methods-list{
        overflow-y: scroll;
    }

</style>